<script lang="ts">
export default {
  layout: 'no-nav',
}
</script>

<template>
  <main class="pb-20 lg:pb-40">
    <slot />
  </main>
</template>
